import React, {useEffect, useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import moment from 'moment';
import {SingleDatePicker} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import service from './api/Service';
import * as util from './components/utils/utility';
import logo from '@tuxedo/bootstrap-theme/images/graphics/jpmc_logo_white.svg';

import {
  TuxAlert,
  TuxButton,
  TuxGlobalHeader,
  TuxLoadingIndicator,
  TuxSelect,
  TuxTextInput,
  TuxBanner,
  TuxModal
} from '@tuxedo/react-component-library';
import * as constants from './components/utils/constants';

function App() {
  const currentYear = moment().format('YYYY');
  const [selCountry, setSelCountry] = useState({
    id: 1001,
    countryCode: "US",
    countryName: "United States",
    activeYN: "Y"
  });
  const [selService, setSelService] = useState({name: 'Basic Information', code: 'BASIC_INFO'});
  const [id, setID] = useState('');
  const [empName, setEmpName] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [dob, setDob] = useState(moment());
  const [focused, setFocused] = useState(false);
  const [labelSSN, setLabelSSN] = useState('Last 5 Digits of Employee SSN');
  const [inValidFields, setInValidFields] = useState({});
  const [errMsg, setErrMsg] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isEmailSentError, setIsEmailSentError] = useState('');
  const [empAccessCode, setEmpAccessCode] = useState('');
  const [isLetterSendLoading, setIsLetterSendLoading] = useState(false);
  const [hideDiv, setHideDiv] = useState('');
  const [selCountryIsAPACEMEA, setSelCountryIsAPACEMEA] = useState(false);
  const [bannerLink, setBannerLink] = useState("");
  const [showSingaporeNotePopupModal, setShowSingaporeNotePopupModal] = useState(false);



  const initialState = {
    selDelMethod: '',
    fax: '',
    cfmFax: '',
    email: '',
    cfmEmail: ''
  };
  const [values, setValues] = useState(initialState || {});
  const deliveryTypes = {"fax": "By Fax", "email": "By Email"};

  const handleChange = (event) => {
    const {target} = event;
    const {name, value} = target;
    event.persist();
    setValues({...values, [name]: value});
    if (name === "selDelMethod") {
      setErrMsg('');
      setInValidFields({});
    }
  };

  const handleAccessCodeChange = (event) => {
    setEmpAccessCode(event.target.value);
    if (!selCountryIsAPACEMEA && empAccessCode.length>8) {
      values.selDelMethod = constants.email;
    }
  };

  const deliveryTypesControl = Object.keys(deliveryTypes).map((type, i) => {
    return (
      <li role="listitem" key={i}>
        <input
          type="radio" id={'selDelType' + i} name="selDelMethod"
          className="tuxedo_radio_button"
          onChange={handleChange}
          value={type}
          checked={values.selDelMethod === type}
        />
        <label htmlFor={'selDelType' + i}>{deliveryTypes[type]}</label>
      </li>

    );
  });

  const onCountryChange = (c) => {
    resetData();
    setID('');
    if (c.countryCode === 'US') {
      empAccessCode.length<=8 ? values.selDelMethod = '' : values.selDelMethod = constants.email;
      setLabelSSN('Last 5 Digits of Employee SSN');
      setHideDiv('');
      setSelCountryIsAPACEMEA(false);
      setShowSingaporeNotePopupModal(false);
    } else if (c.countryCode === 'CA') {
      empAccessCode.length<=8 ? values.selDelMethod = '' : values.selDelMethod = constants.email;
      setLabelSSN('Employee Sid');
      setHideDiv('');
      setSelCountryIsAPACEMEA(false);
      setShowSingaporeNotePopupModal(false);
    } else {
      values.selDelMethod = constants.email;
      setLabelSSN('Employee SID');
      setHideDiv(' hide');
      setSelCountryIsAPACEMEA(true);
      if (c.countryCode === 'SG') {
        setShowSingaporeNotePopupModal(true);
      } else {
        setShowSingaporeNotePopupModal(false);
      }
    }
    getServiceType(c.countryCode);
    setSelService(serviceList[0]);
    setSelCountry(c);
  };

  const getCountryList = () => {
    service._get('/getCountryList').then(function (response) {
      if (response) {
        setCountryList(response);
      }
    }).catch(function (err) {
      console.log(err);
    });
  };
  const getServiceType = (country) => {
    service._get('/getServiceType?country='+country).then(function (response) {
      if (response) {
        setServiceList(response);
      }
    }).catch(function (err) {
      console.log(err);
    });
  };

  const  getBannerLink = () => {
    service._get("/getBannerLink").then(function (response) {
      setBannerLink(response);
    }).catch(function (err) {
      console.log(err);
    });
  };

  useEffect(() => {
    util.trackAppEvent("Page Load", "Home - Vendor");

    getCountryList();
    getServiceType(selCountry.countryCode);
    getBannerLink();
  }, []);

  const onSendLetter = () => {
    const payload = {
      "countryCode": selCountry.countryCode,
      "nationalId": selCountry.countryCode === "US" ? id : null,
      "sid": (selCountry.countryCode === "CA" || selCountryIsAPACEMEA) ? id : null,
      "firstName": empName,
      "dateOfBirth": moment(dob).format('YYYY-MM-DD'),
      "serviceType": selService.code,
      "accessCode": empAccessCode,
      "deliveryType": values.selDelMethod,
      "emailId": (values.selDelMethod === "email" ? values.email : null),
      "faxNumber": (values.selDelMethod === "fax" ? '1' + util.makeNumbersOnly(values.fax) : null)
    };

    const payload2 = {
      "employeeSid": id,
      "accessCode": empAccessCode,
      "deliveryType": values.selDelMethod,
      "deliveryEmailAddress": (values.selDelMethod === "email" ? values.email : null),
    };

    resetData();
    setIsEmailSent(false);
    setIsEmailSentError('');
    setIsLetterSendLoading(true);
    if (isValid() && !isAllowanceLetter() ) {
      service._post('/sendLetter', payload).then(function (response) {
        if (Object.hasOwnProperty.call(response, 'status') && response.status === "Success") {
          setIsEmailSent(true);
          setIsEmailSentError('');
          setIsLetterSendLoading(false);
          util.trackAppEvent("TP Send Form", "Home- Vendor", {
            serviceType: selService.name,
            country: selCountry.countryCode,
            sid: id,
            resultsReturned: "true"
          }, {
            serviceType: selService.name,
            deliveryMethod: values.selDelMethod,
            email: values.selDelMethod === "email" ? values.email.substring(values.email.lastIndexOf("@") + 1) : null
          });
        } else if (Object.hasOwnProperty.call(response, 'status') && response.status === "Error") {
          setIsEmailSent(false);
          setIsEmailSentError(response.errorMessage);
          setIsLetterSendLoading(false);
        } else {
          setIsEmailSent(false);
          if (!response.errorMessage.equals(null)) {//null check for the response
            setIsEmailSentError(response.errorMessage);
          }
          setIsLetterSendLoading(false);
        }
      }).catch(function (err) {
        console.log(err);
        setIsEmailSent(false);
        setIsEmailSentError('Oops! Something went wrong.');
        setIsLetterSendLoading(false);
      });
    } else if (isValid() && isAllowanceLetter()){
      service._post('/sendLetterRequest', payload2).then(function (response) {
        if (Object.hasOwnProperty.call(response, 'responseStatus') && response.responseStatus === "Success") {
          setIsEmailSent(true);
          setIsEmailSentError('');
          setIsLetterSendLoading(false);
        } else if (Object.hasOwnProperty.call(response, 'responseStatus') && response.responseStatus === "Error") {
          setIsEmailSent(false);
          setIsEmailSentError(response.errorMessage);
          setIsLetterSendLoading(false);
        } else {
          setIsEmailSent(false);
          if (!response.errorMessage.equals(null)) {//null check for the response
            setIsEmailSentError(response.errorMessage);
          }
          setIsLetterSendLoading(false);
        }
      }).catch(function (err) {
        console.log(err);
        setIsEmailSent(false);
        setIsEmailSentError('Oops! Something went wrong.');
        setIsLetterSendLoading(false);
      });
    } else {
      setIsLetterSendLoading(false);
    }

  };

  function isAllowanceLetter() {
    return ((selCountry.countryCode === "PH" || selCountry.countryCode === "GB") && selService.code === "SALARY_INFO");
  }

  function isValid() {
    const inValidFields = {};
    let errMsg = '';
    let valid = true;
    if (selCountry.countryCode.length === 0) {
      valid = false;
      errMsg += '<li>Country is a required field</li>';
      inValidFields.selCountry = true;
    }
    if (id.length === 0) {
      valid = false;
      if (selCountry.countryCode === "US") {
        errMsg += '<li>\n' +
          'SSN is a required field</li>';
      } else {
        errMsg += '<li>\n' +
          'Sid is a required field</li>';
      }

      inValidFields.id = true;
    }
    if ((selCountry.countryCode === "US" || selCountry.countryCode === "CA") && empName.trim().length < 2) {
      valid = false;
      errMsg += '<li>\n' +
        'First Name should contain minimum 2 characters</li>';
      inValidFields.empName = true;
    }
    if ((selCountry.countryCode === "US" || selCountry.countryCode === "CA") && dob === null) {
      valid = false;
      errMsg += '<li>\n' +
        'Date of Birth is a required field</li>';
      inValidFields.dob = true;
    }
    if (selService.code.length === 0) {
      valid = false;
      errMsg += '<li>\n' +
        'Service Type is a required field</li>';
      inValidFields.selService = true;
    }
    if (empAccessCode.length === 0) {
      valid = false;
      inValidFields.empAccessCode = true;
      errMsg += '<li>Please enter valid access code</li>';
    }
    if (values.selDelMethod === constants.fax) {
      if (util.makeNumbersOnly(values.fax).length < 10) {
        valid = false;
        inValidFields.fax = true;
        errMsg += '<li>Please enter a valid Fax Number</li>';
      }
      if (util.makeNumbersOnly(values.cfmFax).length < 10) {
        valid = false;
        inValidFields.cfmFax = true;
        errMsg += '<li>Please enter a valid Fax Confirmation Number</li>';
      }
      if (!inValidFields.fax && !inValidFields.cfmFax && util.makeNumbersOnly(values.fax) !== util.makeNumbersOnly(values.cfmFax)) {
        valid = false;
        inValidFields.cfmFax = true;
        errMsg += '<li>\'Fax Confirmation Number\' does not match \'Fax Number\'</li>';
      }
    }

    if (values.selDelMethod === constants.email) {
      if (!util.validateEmail(values.email)) {
        valid = false;
        inValidFields.email = true;
        errMsg += '<li>Please enter a valid Email Address</li>';
      }
      if (!util.validateEmail(values.cfmEmail)) {
        valid = false;
        inValidFields.cfmEmail = true;
        errMsg += '<li>Please enter a valid Confirm Email Address</li>';
      }
      if (!inValidFields.email && !inValidFields.cfmEmail && values.email !== values.cfmEmail) {
        valid = false;
        inValidFields.cfmEmail = true;
        errMsg += '<li>Confirm Email Address does not match with Email Address</li>';
      }
    }

    setErrMsg(errMsg);
    setInValidFields(inValidFields);
    return valid;
  }

  function resetData() {
    setErrMsg('');
    setInValidFields({});
    setIsEmailSent(false);
    setIsEmailSentError('');
  }

  function onExit(exitUrl) {
    util.trackAppEvent("Exit", "Home - Vendor", {}, {}, exitUrl);
  }

  const renderMonthElement = ({month, onMonthSelect, onYearSelect}) => {
    return (
      <div className='tux_date_picker_header'>
        <div>
          <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)} aria-label="select month">
            {moment.months().map((label, val) => (
              <option key={val} value={val}>{label}</option>
            ))}
          </select>
        </div>
        <div>
          <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)} aria-label="select year">
            {Array(new Date().getFullYear() - (new Date().getFullYear() - 81) + 1)
              .fill()
              .map((_, idx) => (
                <option key={idx} value={new Date().getFullYear() - 81 + idx}>
                  {new Date().getFullYear() - 81 + idx}
                </option>
              ))
              .reverse()}
            }
          </select>
        </div>
      </div>
    );
  };

  return (

    <BrowserRouter>
      <div className="app-container bg-light">
        <div>
          <TuxGlobalHeader>
            {/*  <div className="left_side" />*/}

            <a className="navbar-brand text-white center">
              {/*             Employment Verification <br />*/}
              <img src={logo} alt="JPMC logo" />
            </a>

          </TuxGlobalHeader>

          <TuxModal
            open={showSingaporeNotePopupModal}
            type="popup"
            title="Note"
            body={
              <ul>
                <li>For additional verification of ex-employees who were registered with the Monetary Authority of Singapore (MAS) as a licensed representative, please reach out to Referencing_Team@jpmchase.com.</li>
                <li>As per standard company policy, we would need a letter of authorization physically / digitally signed by employee to release the requested employment reference check.</li>
                <li>Please include employee’s JP Morgan SID (Employee code) and JP Morgan work location : Singapore in the subject line of all your emails.</li>
              </ul>
            }
            closeModal={() => setShowSingaporeNotePopupModal(!showSingaporeNotePopupModal)}
          />

          <main className="content-body">
            {/*  <Switch> Routes go here </Switch>*/}

            {selCountryIsAPACEMEA ?
              <TuxBanner dismissible type='info'>
                Employee needs to share the access code with vendor for accessing the forms.
                Please ask the employee to visit &nbsp;
                <a className="empv_link" rel="noopener noreferrer" target="_blank" href={bannerLink}>
                  Employment Verification
                </a> to learn more.
              </TuxBanner> : <> </>}

            <div className="container mt-1">
              <div className="border container pt-5 pr-5 pl-5 pb-2">
                {/*The div below is for accessibility SR only h1.*/}
                <div><h1 className="sr-only">JP Morgan Chase Employment Verification System</h1></div>
                {isLetterSendLoading && <span> Sending Letter... please wait<TuxLoadingIndicator /></span>}
                {isEmailSent && <TuxAlert type="success" heading="" dismissable> Letter Sent</TuxAlert>}
                {isEmailSentError.length > 0 &&
                  <TuxAlert type="danger" heading="" dismissable> Letter Sent Failed. {isEmailSentError}!</TuxAlert>}
                {errMsg.length > 0 ?
                  <TuxAlert
                    type="danger"
                    heading="Oops! There are some missing required fields"
                    dismissable={false}
                  >
                    <div dangerouslySetInnerHTML={{__html: errMsg}} />
                  </TuxAlert> : null}

                <div className="row">
                  <div className="col-12">
                    <form className="form">
                      <div className={'form-group ' + (inValidFields.selCountry ? 'error' : '')}>
                        <label className="tuxedo_label required" htmlFor="country">
                          Select Country
                        </label>
                        <TuxSelect
                          bind_label="countryName"
                          id="country"
                          value={selCountry}
                          placeholder="Please select a country..."
                          options={countryList}
                          onChange={onCountryChange}
                          helperText=""
                          required
                          className="form-control-sm"
                        />
                      </div>
                    </form>
                  </div>

                </div>
                <div className="row">
                  <div className="col-12">
                    <form className="form">
                      <div className={'form-group ' + (inValidFields.id ? 'error' : '')}>
                        <TuxTextInput
                          id="sid"
                          label={labelSSN}
                          onChange={event => setID(event.target.value)}
                          placeholder=""
                          value={id}
                          required
                          small
                          maxLength={selCountry.countryCode === "US" ? 5 : 7}
                        />
                      </div>
                      <div className={'form-group ' + (inValidFields.empName ? 'error' : '') + hideDiv}>
                        <TuxTextInput
                          id="name"
                          label="First 3 Letters of First Name"
                          onChange={event => setEmpName(event.target.value)}
                          placeholder=""
                          value={empName}
                          maxLength={3}
                          minLength={2}
                          required
                          small
                        />
                      </div>
                      <div className={'form-group ' + (inValidFields.dob ? 'error' : '') + hideDiv}>
                        <label className="tuxedo_label required" htmlFor="date_id">Date of Birth</label>
                        <div className="input_feedback" />
                        <SingleDatePicker
                          date={dob}
                          id="date_id"
                          customInputIcon={
                            <i className="icon calendar-o blue">
                              <span className="sr-only">open calendar</span>
                            </i>
                          }
                          displayFormat="MM/DD/YYYY"
                          isOutsideRange={day => !day.isSameOrBefore()}
                          onDateChange={date1 => setDob(date1)}
                          focused={focused}
                          numberOfMonths={1}
                          renderMonthElement={renderMonthElement}
                          onFocusChange={({focused}) => {
                            setFocused(focused);
                          }}
                        /> <span className="input_feedback_span">(MM/DD/YYYY)</span>

                      </div>

                      <div className={'form-group ' + (inValidFields.empAccessCode ? 'error' : '')}>
                        <TuxTextInput
                          id="empAccessCode"
                          label="Employee Access Code"
                          onChange={handleAccessCodeChange}
                          placeholder=""
                          required
                          value={util.makeNumbersOnly(empAccessCode.toString())}
                          small
                          maxLength={16}
                        />
                      </div>

                      <div className={'form-group ' + (inValidFields.selService ? 'error' : '')}>
                        <label className="tuxedo_label required" htmlFor="service">
                          Select Service Type
                        </label>
                        <TuxSelect
                          bind_label="name"
                          id="service"
                          value={selService}
                          placeholder="Please select an option..."
                          options={serviceList}
                          onChange={value => {
                            setSelService(value);
                          }}
                          helperText=""
                          required
                          className="form-control-sm"
                        />
                      </div>

                      <>
                        <div>
                          {!selCountryIsAPACEMEA  && empAccessCode.length<=8 ?
                            <fieldset>
                              <legend className="tuxedo_label sr-only">Select a delivery method</legend>
                              <h3 id="empVerDelivMethod" className="md" tabIndex="-1">Choose Delivery
                                Method
                              </h3>
                              <ul className="unstyled input-group" role="list">
                                {deliveryTypesControl}

                              </ul>
                            </fieldset> : <> </>}
                          {!selCountryIsAPACEMEA && empAccessCode.length<=8 ? <br /> : <> </>}

                          {values.selDelMethod === constants.fax ?
                            <div className="row">
                              <div className="col-md-12">
                                <div className={'form-group ' + (inValidFields.fax ? 'error' : '')}>
                                  <span className="fax_prefix">+1</span>
                                  <TuxTextInput
                                    id="fax"
                                    label="Fax Number"
                                    onChange={handleChange}
                                    required
                                    value={util.makePhoneNumber(values.fax)}
                                    type="tel"
                                    maxLength={14}
                                    small
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className={'form-group ' + (inValidFields.cfmFax ? 'error' : '')}>
                                  <label className="tuxedo_label required" htmlFor="cfmFax">
                                    Confirm Fax Number
                                  </label>
                                  <span className="fax_prefix">+1</span>
                                  <input
                                    id="cfmFax"
                                    name="cfmFax"
                                    onChange={handleChange}
                                    required
                                    className="form-control form-control-sm"
                                    value={util.makePhoneNumber(values.cfmFax)}
                                    type="tel"
                                    maxLength={14}
                                    onPaste={(e) => {
                                      e.preventDefault();
                                      return false;
                                    }}
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </div> : null}


                          {values.selDelMethod === constants.email || selCountryIsAPACEMEA || empAccessCode.length>8 ?
                            <div className="row">
                              <div className="col-md-12">
                                <div className={'form-group ' + (inValidFields.email ? 'error' : '')}>
                                  <label className="tuxedo_label required" htmlFor="email">
                                    Email Address
                                  </label>
                                  <input
                                    id="email"
                                    name="email"
                                    className="form-control form-control-sm"
                                    onChange={handleChange}
                                    required
                                    value={values.email}
                                    type="email"
                                    maxLength={80}

                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className={'form-group ' + (inValidFields.cfmEmail ? 'error' : '')}>
                                  <label className="tuxedo_label required" htmlFor="cfmEmail">
                                    Confirm Email Address
                                  </label>
                                  <input
                                    id="cfmEmail"
                                    name="cfmEmail"
                                    className="form-control form-control-sm"
                                    onChange={handleChange}
                                    required
                                    value={values.cfmEmail}
                                    type="email"
                                    maxLength={80}
                                    onPaste={(e) => {
                                      e.preventDefault();
                                      return false;
                                    }}
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </div> : null}
                          {values.selDelMethod || empAccessCode.length>8 ?
                            <div className="text-right">
                              <TuxButton variant="primary" disabled={isLetterSendLoading} onClick={onSendLetter}>Send</TuxButton>
                            </div> :
                            <></>}

                        </div>
                      </>

                    </form>
                  </div>

                </div>

              </div>
            </div>


          </main>

          <footer className="footer" role="contentinfo" aria-label="Footer">
            <ul className="links">
              <li onClick={() => onExit("http://www.jpmorganchase.com")}>
                <a
                  href="http://www.jpmorganchase.com"
                  rel="noopener noreferrer" target="_blank"
                >JPMorgan Chase
                </a>
              </li>
              <li onClick={() => onExit("http://www.jpmorgan.com/")}>
                <a
                  href="http://www.jpmorgan.com/"
                  rel="noopener noreferrer" target="_blank"
                >J.P. Morgan
                </a>
              </li>
              <li onClick={() => onExit("http://www.chase.com/")}>
                <a
                  href="http://www.chase.com/"
                  rel="noopener noreferrer" target="_blank"
                >Chase
                </a>
              </li>
            </ul>
            <p>
              &copy;{currentYear} JPMorgan Chase &amp; Co. All rights
              reserved.
            </p>
          </footer>


        </div>
      </div>
    </BrowserRouter>
  );

}

export default App;
